export function deepClone(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    const newArr = [];
    for (const item of obj) {
      newArr.push(deepClone(item));
    }
    return newArr;
  }

  const newObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      newObj[key] = deepClone(obj[key]);
    }
  }

  return newObj;
}
//------------------------------------------
export const findDuplicateChassis = (
  allChassis,
  data,
  duplicates,
  responseContent,
  chassisData
) => {
  if (Object.keys(responseContent).length > 0) {
    Object.values(responseContent).forEach((item, key) => {
      if (item[0].chassisNo) {
        allChassis.push(item[0].chassisNo);
      }
    });
  }
  if (chassisData.length > 0) {
    chassisData.forEach((item, key) => {
      if (item.chassisNo) {
        allChassis.push(item.chassisNo);
      }
    });
  }
  Object.values(data).forEach((item, key) => {
    if (Array.isArray(item)) {
      return findDuplicateChassis(allChassis, item, duplicates, [], []);
    }
    if (item.chassisNo) {
      allChassis.push(item.chassisNo);
    }
  });

  if (allChassis.length > 0) {
    duplicates = allChassis.filter(
      (chassis, index) => allChassis.indexOf(chassis) !== index
    );
  }
  return duplicates;
};
//------------------------------------------
export const getNewData = (data, duplicateChassis) => {
  Object.values(data).forEach((item, key) => {
    if (Array.isArray(item)) {
      return getNewData(item, duplicateChassis);
    }
    if (item.chassisNo && duplicateChassis.includes(item.chassisNo)) {
      item.duplicate = true;
    }
  });
};
//------------------------------------------
export const checkDuplicatePriority = (priorityVal, data) => {
  if (priorityVal === "0") {
    return false;
  } else if (priorityVal) {
    return data.some((item) => item.priorityNo == priorityVal);
  } else {
    return false;
  }
};
//------------------------------------------
export const validateRange = (priorityVal, prodTargetVal) => {
  const pattern = /^[1-9][0-9]*[ab]?$/;
  if (!pattern.test(priorityVal)) {
    return false;
  }
  if (
    priorityVal.includes("a") ||
    priorityVal.includes("b") ||
    priorityVal.includes("ab")
  ) {
    const parts = priorityVal.split(/([ab])/);
    const num = parseInt(parts[0]);
    if (num < 1 || num > parseInt(prodTargetVal)) {
      return false;
    }
  }
  return true;
};
