import { useState, useEffect, useCallback, Fragment } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  Container,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { checkDuplicatePriority, validateRange } from "./utils.js";
import "./assembly.css";
import { useSelector } from "react-redux";
import TableHeader from "../ProductionTableHeader/TableHeader";
import { DailyReviewPresenter } from "../../dailyReview-presenter";
import httpClient from "../../../../https/httpClient.js";
import _ from "lodash";
import { event } from "jquery";

const { REACT_APP_API_BASE_URL } = process.env;

export default function AssemblyTable({
  responseContent,
  setResponseContent,
  selectedLineValue,
  productionTargetVal,
  updateSimulatorData,
  layoutData,
  layoutDataCopy,
  prepData,
  refresh,
  getChassisPreparationQueueAndProductionLines,
}) {
  const authInfo = useSelector((state) => state.userAuthInfoState);
  // ------------------- Define Role --------------------
  const topTableValidRole =
    authInfo.roles &&
    (authInfo.roles?.includes("user.admin") ||
      authInfo.roles?.includes("production-manager") ||
      authInfo.roles?.includes("inventory_staff") ||
      authInfo.roles?.includes("analyst_staff"));

  const jobsTableValidRole =
    authInfo.roles &&
    (authInfo.roles?.includes("user.admin") ||
      authInfo.roles?.includes("production-manager") ||
      authInfo.roles?.includes("analyst_staff"));

  const editPriorityValidRole =
    authInfo.roles &&
    (authInfo.roles?.includes("user.admin") ||
      authInfo.roles?.includes("production-manager") ||
      authInfo.roles?.includes("inventory_staff"));
  //--------------------------------------------------------------------------------------------
  const dailyReviewPresenter = new DailyReviewPresenter();
  const [editedNotes, setEditedNotes] = useState("");
  const [isInputField, setIsInputField] = useState(false);
  const [rowIndex, setRowIndex] = useState(); // This is for stations
  const [stationName, setStationName] = useState();
  const [outerRowIndex, setOuterRowIndex] = useState();
  const [innerRowIndex, setInnerRowIndex] = useState();
  const [chassisNo, setChassisNo] = useState("");
  const [editedChassisNo, setEditedChassisNo] = useState("");
  const [requestBody, setRequestBody] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [isPriorityCol, setIsPriorityCol] = useState(false);
  const [priorityVal, setPriorityVal] = useState("");
  const [completedItems, setCompletedItems] = useState("");
  const [toggleExpand, setToggleExpand] = useState(false);
  const [expandedRow, setExpandedRow] = useState();

  const stationAlertOptions = [
    { label: "Select", value: "" },
    { label: "Alert", value: "Alert" },
    { label: "Work Active", value: "Work Active" },
    { label: "Work Complete", value: "Work Complete" },
    { label: "Stores", value: "Stores" },
    { label: "Maintenance", value: "Maintenance" },
  ];

  const fetchSimulatedData = async (lineVal) => {
    try {
      // const response = await dailyReviewPresenter.fetchSimulatedData(lineVal);
      let deliveryQueues = [];
      if (lineVal === "main-line") {
        const deliveryQueueResponse = await httpClient.get(
          "api/get_all_chassis_delivery_queues"
        );
        deliveryQueues = deliveryQueueResponse.data;
        const numberOfRemainingDeliveryQueueItems = 50 - deliveryQueues.length;
        const generatedDeliveryQueueItems = Array.from(
          { length: numberOfRemainingDeliveryQueueItems },
          (v, i) => {
            let a = {
              id: null,
              chassisNo: null,
              model: null,
              dealer: null,
              notes: null,
              priorityNo: null,
              duplicate: null,
            };
            return a;
          }
        );
        deliveryQueues = deliveryQueues.concat(generatedDeliveryQueueItems);
      }

      const line = _.first(lineVal.split("-"));
      const arrivalQueueResponse = await httpClient.get(
        `api/get_chassis_arrival_queues_by_line/${line}`
      );
      let arrivalQueues = arrivalQueueResponse.data;
      const numberOfRemainingArrivalQueueItems = 20 - arrivalQueues.length;
      const generatedArrivalQueueItems = Array.from(
        { length: numberOfRemainingArrivalQueueItems },
        (v, i) => {
          let a = {
            id: null,
            chassisNo: null,
            model: null,
            dealer: null,
            notes: null,
            priorityNo: null,
            duplicate: null,
          };
          return a;
        }
      );
      arrivalQueues = arrivalQueues.concat(generatedArrivalQueueItems);

      let allData = {
        "Chassis to be Delivered": deliveryQueues,
        "Chassis Arrived": arrivalQueues,
      };
      // allData['Chassis to be Delivered'] = deliveryQueues
      await updateSimulatorData(allData);
    } catch (error) {
      console.log("Something is wrong", error);
    }
  };

  const fetchCompletedItems = async (lineVal) => {
    try {
      const completedItemsFormatted =
        await dailyReviewPresenter.fetchCompletedItems(lineVal);
      setCompletedItems(completedItemsFormatted);
    } catch (error) {
      console.log("Something is Wrong!", error);
    }
  };

  useEffect(() => {
    fetchSimulatedData(selectedLineValue);
    fetchCompletedItems(selectedLineValue);
    let id = setInterval(()=>{
      fetchSimulatedData(selectedLineValue);
    }, 30 * 1000);
    return () => clearInterval(id);
  }, [selectedLineValue, refresh]);

  const handleNotesInputField = (stationName, index, notes, chassis) => {
    setEditedNotes(notes);
    setIsInputField(true);
    setRowIndex(index);
    setChassisNo(chassis);
    setStationName(stationName);
  };
  const handleNotes = (event) => {
    setEditedNotes(event.target.value);
    responseContent[stationName][rowIndex].notes = event.target.value;
    setRequestBody({
      chassisNo: chassisNo.toString() ?? "",
      notes: event.target.value ?? "",
    });
  };
  const handlePostNotes = (event) => {
    if (event.key === "Enter") {
      const apiUrl = `${REACT_APP_API_BASE_URL}/update_notes_by_chassis_number/${chassisNo.toString()}`;
      setIsInputField(false);

      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      };

      const delayPost = setTimeout(() => {
        fetch(apiUrl, requestOptions)
          .then((response) => response.json())
          .then((response) => {})
          .catch((error) => {
            console.log("Update error", error);
          });
      }, 1500);
      return () => clearTimeout(delayPost);
    }
    if (event.key === "Escape") {
      setIsInputField(false);
    }
  };

  //----------------------------------------------------------------------
  const handleChassisInput = (mainRowIndex, subRowIndex, rowName) => {
    setOuterRowIndex(mainRowIndex);
    setInnerRowIndex(subRowIndex);
    setIsInputField(true);
    setIsPriorityCol(false);
  };
  const handleChassisEdit = (rowName, event, subRowIndex) => {
    setEditedChassisNo(event.target.value);
    layoutData[rowName][subRowIndex].chassisNo = event.target.value;
  };

  const handleGetChassisData = async (
    rowName,
    event,
    subRowIndex,
    chassisNo,
    row
  ) => {
    setIsVisible(false);
    if (event.key === "Enter") {
      setIsVisible(true);
      setIsInputField(false);

      if (chassisNo !== "") {
        try {
          const api_response = await httpClient.post(`api/check_duplicate_chassis_queue/${chassisNo}`)
          if(api_response?.data?.isSuccess){
            toast.error("Chassis number already exists..!");
            await fetchSimulatedData(selectedLineValue);
            return;
          }
        } catch (error) {
          console.log("Error checking duplicate chassis", error);
          return;
        }
      }
      try {
        await toast.promise(
          httpClient.post("api/manage_chassis_queue", {
            id: row.id,
            chassisNo: chassisNo || "",
          }),
          {
            pending: "Request pending",
          }
        );
        toast.success("Request Success..! 👌");
      } catch (error) {
        if (error?.response?.status === 404) {
          toast.error(error?.response?.data?.detail || "Not Found!");
        }
      }
      await fetchSimulatedData(selectedLineValue);
    }
  };
  const handleOnBlur = (rowName, event, subRowIndex) => {
    if (!isVisible) {
      layoutData[rowName][subRowIndex].chassisNo =
        layoutDataCopy[rowName][subRowIndex].chassisNo;
    }
  };
  const handleGetChassisNo = (stationName, indexVal, row) => {};

  //-----------------------------------------------------------
  const handlePriorityInput = (mainRowIndex, subRowIndex, rowName) => {
    setOuterRowIndex(mainRowIndex);
    setInnerRowIndex(subRowIndex);
    setIsInputField(true);
    setIsPriorityCol(true);
    setPriorityVal("");
  };
  const handleChangePriority = (rowName, event, subRowIndex) => {
    setPriorityVal(event.target.value);
    layoutData[rowName][subRowIndex].priorityNo = event.target.value;
  };
  const handleSetPriority = async (rowName, event, subRowIndex, row) => {
    if (event.key === "Enter") {
      setIsInputField(false);
      setIsPriorityCol(false);

      const isPriorityValidated = validateRange(
        priorityVal,
        productionTargetVal
      );
      layoutData[rowName][subRowIndex].priorityNo = priorityVal;
      const isDuplicatePriority = checkDuplicatePriority(
        priorityVal,
        layoutDataCopy["Chassis Arrived"]
      );
      if (priorityVal > productionTargetVal || !isPriorityValidated) {
        layoutData[rowName][subRowIndex].priorityNo = "";
        setPriorityVal("");
        toast.error("Invalid Input");
      } else {
        if (isDuplicatePriority && rowName === "Chassis Arrived") {
          toast.error("Can Not Assign Duplicate Priority");
          layoutData["Chassis Arrived"][subRowIndex].priorityNo = "";
        } else {
          layoutDataCopy["Chassis Arrived"][subRowIndex].priorityNo =
            priorityVal;
        }
      }

      const line = _.first(selectedLineValue.split("-"));
      if (isPriorityValidated && !isDuplicatePriority) {
        try {
          await toast.promise(
            httpClient.put(`api/update_chassis_arrival_queue/${row.id}`, {
              id: row.id,
              chassisNo: row.chassisNo || "",
              priorityNo: row.priorityNo || "",
              model: row.model || "",
              stationAlert: row.stationAlert || "",
              notes: row.notes || "",
              line: line,
            }),
            {
              pending: "Request pending",
            }
          );
          toast.success("Request Success");
          fetchSimulatedData(selectedLineValue);
          // await updateSimulatorData(response[0]);
        } catch (error) {
          console.log("error", error);
          toast.error("Invalid Input");
        }
      }
    }
  };
  const handleExpandRow = (row, subRowIndex, rowName, mainRowIndex) => {
    setToggleExpand((prevState) => !prevState);
    setExpandedRow(mainRowIndex);
  };

  const handleCheckBoxChecked = async (event, row) => {
    const line = _.first(selectedLineValue.split("-"));
    let requestBody = {
      chassisNo: row.chassisNo || "",
      stationName: row.stationName || "",
      lineNumber: row.lineNumber,
      model: row.model || "",
      stationAlert: row.stationAlert || "",
      notes: row.notes || "",
      priorityNo: row.priorityNo || "",
      completed: row.completed,
      hold: row.hold,
      line: line,
      startedOn: row.startedOn || null,
      completedOn: row.completedOn || null,
    };

    if (event.target.checked) {
      requestBody.completed = "True";
    } else {
      requestBody.completed = "False";
    }

    try {
      const response = await toast.promise(
        dailyReviewPresenter.updateProcessedData(row.id, requestBody),
        {
          pending: "Request Pending",
          success: "Request resolved 👌",
          error: "Request rejected 🤯",
        }
      );
      const groupedData = dailyReviewPresenter.prepareProductionReviewData(
        response.production_line_data
      );

      await getChassisPreparationQueueAndProductionLines(selectedLineValue);
      await fetchCompletedItems(selectedLineValue);
      await fetchSimulatedData(selectedLineValue);

      // await setResponseContent(groupedData);
      // setCompletedItems(response.completed_items);
      // await updateSimulatorData(response.simulator_data[0]);
      // await prepData(response.chassis_prep_data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleAlertOptionsChange = async (station, value) => {
    try {
      const line = _.first(selectedLineValue.split("-"));
      let model = {
        chassisNo: station.chassisNo || "",
        stationName: station.stationName || "",
        lineNumber: station.lineNumber,
        model: station.model || "",
        stationAlert: value || "",
        notes: station.notes || "",
        priorityNo: station.priorityNo || "",
        completed: station.completed,
        hold: station.hold,
        line: line,
        startedOn: station.startedOn || null,
        completedOn: station.completedOn || null,
      };
      await httpClient.put(
        `api/update_station_alert_of_production_line/${station?.id}`,
        model
      );
      await getChassisPreparationQueueAndProductionLines(selectedLineValue);
    } catch (error) {
      console.log("Error", error);
      toast.error("Something is wrong!");
    }
  };

  return (
    <Container
      className="p-0 ms-0"
      style={{ textAlign: "center", maxWidth: "1150px" }}
    >
      <TableContainer
        component={Paper}
        style={{ marginTop: "8px", paddingBottom: "24px" }}
      >
        <Table size="small" className="p-0">
          {/* <Header /> */}
          <TableHeader headers={dailyReviewPresenter.assemblyTableHeader} />
          <TableBody>
            {topTableValidRole && Object.keys(layoutData).length > 0 ? (
              <>
                {Object.keys(layoutData).map((rowName, mainRowIndex) => (
                  <Fragment key={mainRowIndex}>
                    {layoutData[rowName].map((row, subRowIndex) => (
                      <TableRow
                        key={subRowIndex}
                        style={{
                          backgroundColor: "#F9F9F9",
                          fontFamily: "League Spartan, sans-serif",
                        }}
                      >
                        {subRowIndex === 0 && (
                          <>
                            <TableCell
                              className="cursor-pointer"
                              rowSpan={layoutData[rowName].length}
                              onClick={() =>
                                handleExpandRow(
                                  row,
                                  subRowIndex,
                                  rowName,
                                  mainRowIndex
                                )
                              }
                              style={{
                                backgroundColor:
                                  rowName === "Chassis Arrived" &&
                                  layoutData[rowName].filter((key) => {
                                    return key.chassisNo && key.chassisNo;
                                  }).length < 7
                                    ? "#f39ba2"
                                    : "inherit",
                              }}
                            >
                              <div className="d-flex align-items-center justify-content-between gap-1">
                                <Typography
                                  variant="body1"
                                  style={{
                                    fontFamily: "League Spartan, sans-serif",
                                  }}
                                >
                                  {rowName}
                                </Typography>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="9"
                                  viewBox="0 0 16 9"
                                  fill="none"
                                >
                                  <path
                                    d="M2 2L8 8L14 2"
                                    stroke="#25282B"
                                    strokeWidth="2"
                                    strokeLinecap="square"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            </TableCell>
                            {Array(6)
                              .fill()
                              .map((_, i) => (
                                <TableCell
                                  key={i}
                                  className={`expandable-row ${
                                    toggleExpand && expandedRow === mainRowIndex
                                      ? "close"
                                      : ""
                                  }`}
                                ></TableCell>
                              ))}
                          </>
                        )}
                        <TableCell
                          className={`expandable-row ${
                            toggleExpand && expandedRow === mainRowIndex
                              ? "expand"
                              : "close"
                          }`}
                          style={{
                            height: "30px",
                            backgroundColor: `${
                              rowName === "Chassis to be Delivered"
                                ? "#ececec"
                                : "inherit"
                            }`,
                          }}
                          onClick={() =>
                            handlePriorityInput(
                              mainRowIndex,
                              subRowIndex,
                              rowName
                            )
                          }
                        >
                          {editPriorityValidRole &&
                          isInputField &&
                          rowName === "Chassis Arrived" &&
                          innerRowIndex === subRowIndex &&
                          isPriorityCol ? (
                            <input
                              style={{ width: "100%" }}
                              type="text"
                              pattern="^[1-9][0-9]*[ab]?$"
                              value={
                                layoutData[rowName][subRowIndex]?.priorityNo ??
                                ""
                              }
                              onChange={(event) =>
                                handleChangePriority(
                                  rowName,
                                  event,
                                  subRowIndex
                                )
                              }
                              onKeyDown={(event) =>
                                handleSetPriority(
                                  rowName,
                                  event,
                                  subRowIndex,
                                  row
                                )
                              }
                              onBlur={(event) =>
                                handleOnBlur(rowName, event, subRowIndex)
                              }
                            ></input>
                          ) : (
                            <Typography
                              variant="body1"
                              style={{
                                fontFamily: "League Spartan, sans-serif",
                              }}
                            >
                              {row.priorityNo}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell
                          className={`expandable-row ${
                            toggleExpand && expandedRow === mainRowIndex
                              ? "expand"
                              : "close"
                          }`}
                          onClick={() =>
                            handleChassisInput(
                              mainRowIndex,
                              subRowIndex,
                              rowName
                            )
                          }
                        >
                          {editPriorityValidRole &&
                          isInputField &&
                          rowName === "Chassis to be Delivered" &&
                          innerRowIndex === subRowIndex &&
                          !isPriorityCol ? (
                            <input
                              style={{ width: "100%" }}
                              type="text"
                              value={
                                layoutData[rowName][subRowIndex]?.chassisNo
                              }
                              onChange={(event) =>
                                handleChassisEdit(rowName, event, subRowIndex)
                              }
                              onKeyDown={(event) =>
                                handleGetChassisData(
                                  rowName,
                                  event,
                                  subRowIndex,
                                  layoutData[rowName][subRowIndex]?.chassisNo,
                                  row
                                )
                              }
                              onBlur={(event) =>
                                handleOnBlur(rowName, event, subRowIndex)
                              }
                            ></input>
                          ) : (
                            <Typography
                              variant="body1"
                              style={{
                                fontFamily: "League Spartan, sans-serif",
                              }}
                            >
                              {row.chassisNo}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell
                          className={`expandable-row ${
                            toggleExpand && expandedRow === mainRowIndex
                              ? "expand"
                              : "close"
                          }`}
                        >
                          <Typography
                            variant="body1"
                            style={{
                              fontFamily: "League Spartan, sans-serif",
                            }}
                          >
                            {row.model}
                          </Typography>
                        </TableCell>
                        <TableCell
                          className={`expandable-row ${
                            toggleExpand && expandedRow === mainRowIndex
                              ? "expand"
                              : "close"
                          }`}
                        >
                          <Typography
                            variant="body1"
                            style={{
                              fontFamily: "League Spartan, sans-serif",
                            }}
                          >
                            {" "}
                            {row.dealer}{" "}
                          </Typography>
                        </TableCell>
                        <TableCell
                          className={`expandable-row ${
                            toggleExpand && expandedRow === mainRowIndex
                              ? "expand"
                              : "close"
                          }`}
                        >
                          <Typography
                            variant="body1"
                            style={{
                              fontFamily: "League Spartan, sans-serif",
                            }}
                          >
                            {" "}
                            {row.notes}{" "}
                          </Typography>
                        </TableCell>
                        <TableCell
                          className={`expandable-row ${
                            toggleExpand && expandedRow === mainRowIndex
                              ? "expand"
                              : "close"
                          }`}
                        >
                          <Typography
                            variant="body1"
                            style={{
                              fontFamily: "League Spartan, sans-serif",
                            }}
                          ></Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Fragment>
                ))}
              </>
            ) : (
              <Typography
                variant="body1"
                className="loading text-start py-1 ps-3"
              >
                Data not Found
              </Typography>
            )}
            {responseContent.length > 0 ? (
              <>
                {topTableValidRole && (
                  <Fragment>
                    {responseContent.map((station, index) => (
                      <TableRow
                        key={index}
                        style={{
                          background: `${
                            index % 2 === 0 ? "#eaeae8" : "#F9F9F9"
                          }`,
                          borderTop: `${
                            index === 0 && index === responseContent.length - 1
                              ? "20px solid #ffff"
                              : "inherit"
                          }`,
                        }}
                      >
                        <TableCell>
                          <Typography
                            variant="body1"
                            style={{
                              fontFamily: "League Spartan, sans-serif",
                            }}
                          >
                            {station.lineNumber.toString().padStart(2, "0")}{" "}
                            {station.stationName}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={2}>
                          <Typography
                            variant="body1"
                            style={{
                              fontFamily: "League Spartan, sans-serif",
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            {/* Removable */}
                            <Checkbox
                              disabled={!station.chassisNo}
                              checked={station.completed}
                              onChange={(event) =>
                                handleCheckBoxChecked(event, station)
                              }
                            />
                            {station.chassisNo && station.priorityNo
                              ? `${station.priorityNo})${station.chassisNo}`
                              : ""}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            style={{
                              fontFamily: "League Spartan, sans-serif",
                            }}
                          >
                            {station.model}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            style={{ fontFamily: "League Spartan, sans-serif" }}
                          >
                            <Select
                              name="stationAlert"
                              value={station?.stationAlert ?? ""}
                              onChange={(event) =>
                                handleAlertOptionsChange(
                                  station,
                                  event.target.value
                                )
                              }
                              className="station_alert_dropdown"
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                              style={{
                                width: "100%",
                                fontFamily: "League Spartan, sans-serif",
                                backgroundColor:
                                  station?.stationAlert === "Alert"
                                    ? "#FF0000"
                                    : station?.stationAlert === "Work Active"
                                    ? "#FFA500"
                                    : station?.stationAlert === "Work Complete"
                                    ? "#008000"
                                    : station?.stationAlert === "Stores"
                                    ? "#0000FF"
                                    : station?.stationAlert === "Maintenance"
                                    ? "#FFFFFF"
                                    : "transparent",
                                color:
                                  station?.stationAlert === "Alert" ||
                                  station?.stationAlert === "Work Active" ||
                                  station?.stationAlert === "Work Complete" ||
                                  station?.stationAlert === "Stores"
                                    ? "white"
                                    : station?.stationAlert === "Maintenance"
                                    ? "black"
                                    : "black",
                              }}
                            >
                              {stationAlertOptions.map((item) => (
                                <MenuItem
                                  value={item.value}
                                  style={{
                                    fontFamily: "League Spartan, sans-serif",
                                  }}
                                >
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </Typography>
                        </TableCell>
                        <TableCell
                          onClick={() =>
                            handleNotesInputField(
                              station.stationName,
                              index,
                              station.notes,
                              station.chassisNo
                            )
                          }
                        >
                          {isInputField &&
                          index === rowIndex &&
                          station === stationName ? (
                            <input
                              type="text"
                              value={editedNotes}
                              onChange={handleNotes}
                              onKeyDown={handlePostNotes}
                            />
                          ) : (
                            <Typography
                              variant="body1"
                              style={{
                                fontFamily: "League Spartan, sans-serif",
                              }}
                            >
                              {station.notes}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          <button
                            className="chassis-btn d-flex align-items-center justify-content-center flex-column"
                            onClick={() =>
                              handleGetChassisNo(
                                station.stationName,
                                index,
                                station
                              )
                            }
                          >
                            <span className="d-block">&#8593;</span>
                            <span className="chassis-btn--tooltip">
                              Add To Chassis on hold
                            </span>
                          </button>
                          {station?.hold ? (
                            <Typography
                              variant="body1"
                              style={{
                                fontFamily: "League Spartan, sans-serif",
                              }}
                            >
                              {station?.chassisNo}
                            </Typography>
                          ) : (
                            <></>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </Fragment>
                )}
              </>
            ) : (
              <Typography
                variant="body1"
                className="loading text-start py-1 ps-3"
              >
                Data not Found
              </Typography>
            )}
          </TableBody>
        </Table>
        {jobsTableValidRole && (
          <>
            <Table style={{ marginTop: "20px" }}>
              <TableBody>
                <TableRow className="mt-5">
                  <TableCell style={{ width: "203px" }}>
                    <Typography>JOBS FOR THE WEEK</Typography>
                  </TableCell>
                  <TableCell>{completedItems}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </>
        )}
      </TableContainer>
    </Container>
  );
}
