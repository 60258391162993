import "./App.css";
import { ToastContainer } from "react-toastify";
import ScheduleGrid from "./app/features/scheduleGrid/scheduleGrid";
import DailyReview from "./app/features/dailyReview/dailyReview";
import { Route, Routes } from "react-router-dom";
import Layout from "./app/common/Layout";
import PageNotFound from "./app/common/PageNotFound";
import Login from "./app/authentications/Login";
import RequireAuth from "./app/authentications/RequireAuth";
import ResponsiveForm from "./app/features/scheduleFeed/ResponsiveForm";
import ProductionForm from "./app/features/dailyReview/components/productionForm/ProductionForm";
import ScheduleFeed from "./app/features/scheduleFeed/scheduleFeed";
import { useSelector } from "react-redux";
import { IUserInfo } from "./app/store/actions";
import PickList from "./app/features/pickList/PickList";
import ProductionReviewSetting from "./app/features/dailyReview/components/ProductionReviewSetting/ProductionReviewSetting";

function App() {
  const authInfo: IUserInfo = useSelector(
    (state: any) => state.userAuthInfoState
  );
  return (
    <>
      <Routes>
        <Route path="/home" element={<Login></Login>} />
        <Route path="/" element={<Layout></Layout>}>
          {authInfo?.roles?.includes("user.admin") ? (
            <>
              <Route element={<RequireAuth />}>
                <Route path="/" element={<ScheduleFeed></ScheduleFeed>} />
                <Route
                  path="/daily-review"
                  element={<DailyReview></DailyReview>}
                />
                <Route
                  path="/ScheduleGrid"
                  element={<ScheduleGrid></ScheduleGrid>}
                />
                <Route
                  path="/production-review-setting"
                  element={<ProductionReviewSetting />}
                />
                <Route
                  path="/form"
                  element={<ResponsiveForm></ResponsiveForm>}
                />
                <Route
                  path="/schedule"
                  element={<ScheduleFeed></ScheduleFeed>}
                />
                <Route path="/pick-list" element={<PickList></PickList>} />
              </Route>
            </>
          ) : authInfo?.roles?.includes("store") ? (
            <>
              <Route element={<RequireAuth />}>
                <Route path="/" element={<PickList />} />
                <Route path="/pick-list" element={<PickList />} />
              </Route>
            </>
          ) : authInfo?.roles?.includes("production-manager") ? (
            <Route element={<RequireAuth />}>
              <Route path="/" element={<DailyReview />} />
              <Route path="/daily-review" element={<DailyReview />} />
              <Route path="/production-review-setting" element={<ProductionReviewSetting></ProductionReviewSetting>} />
            </Route>
          ) : (
            <>
              <Route element={<RequireAuth />}>
                <Route path="/" element={<DailyReview></DailyReview>} />
                <Route
                  path="/daily-review"
                  element={<DailyReview></DailyReview>}
                />
              </Route>
            </>
          )}
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
