import {
  Checkbox,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { IUserInfo } from "../../../../store/actions";
import _ from "lodash";
import httpClient from "../../../../https/httpClient";
const { REACT_APP_API_BASE_URL } = process.env;

const prepTypes = {
  chassis_prep: 0,
  wall_assembly_prep: 1,
  roof_assembly_prep: 2,
  doors_and_drawers: 3,
  furniture_prep: 4,
  cnc_prep: 5,
};
export default function Preparation() {
  const authInfo: IUserInfo = useSelector(
    (state: any) => state.userAuthInfoState
  );
  const [allPrepData, setALLPrepData] = useState<any>([]);
  const [loader, setLoader] = useState(false);

  const stationAlertOptions = [
    { label: "Select", value: "" },
    { label: "Alert", value: "Alert" },
    { label: "Work Active", value: "Work Active" },
    { label: "Work Complete", value: "Work Complete" },
    { label: "Stores", value: "Stores" },
    { label: "Maintenance", value: "Maintenance" },
  ];

  const getPreparationData = async () => {
    // setLoader(true);
    // const responseTwo = await fetch(
    //   `${REACT_APP_API_BASE_URL}/preparation_data_all`
    // );
    // const responseDataTwo = await responseTwo.json();
    // console.log(responseDataTwo);
    const result = await httpClient.get(
      `api/get_all_chassis_preparation_queue_report`
    );

    let formatedData: any = {};
    _.forIn(
      _.groupBy(result.data, (d) => {
        return d.stationName;
      }),
      function (value, key) {
        let obj: any = {};
        _.each(value, (v) => {
          if (
            !v.chassisPreparationQueues ||
            v.chassisPreparationQueues.length == 0
          ) {
            obj[v.line] = [
              {
                id: "",
                chassisNo: "",
                checked: false,
                model: "",
                priorityNo: "",
                stationAlert: ""
              },
            ];
          } else {
            obj[v.line] = _.map(v.chassisPreparationQueues, (q) => {
              return {
                id: v.id,
                chassisNo: q.chassisNo,
                checked: q.checked,
                model: q.model,
                priorityNo: q.priorityNo,
                stationAlert: q.stationAlert
              };
            });
          }
        });
        formatedData[key] = [obj];
      }
    );
    // console.log([formatedData]);
    setALLPrepData([formatedData]);
    // setLoader(false);
  };

  const handleCheckboxChange = async (event: any, row: any) => {
    const isChecked = event.target.checked;
    let requestBody = {
      chassisNo: row.chassisNo,
      checked: isChecked,
    };
    try {
      await httpClient.put(
        `api/move_chassis_from_arrival_queue_to_preparation_queue/${row.id}`,
        requestBody
      );
      await getPreparationData();
      toast.success("Saved Successfully!");
    } catch (e) {
      toast.error("Failed!");
    }
  };

  // const handleCheckboxChange =
  //   (station: string, line: string, row: any, index: any) =>
  //   (event: React.ChangeEvent<HTMLInputElement>) => {
  //     const isChecked = event.target.checked;
  //     let requestBody = {
  //       station_name: station,
  //       line_type: line,
  //       chassisNo: row.chassisNo,
  //       model: row.model,
  //       dealer: row.dealer,
  //       notes: row.notes,
  //       isChecked: isChecked,
  //     };
  //     axios
  //       .post(`${REACT_APP_API_BASE_URL}/update-checkbox`, requestBody)
  //       .then((response) => {
  //         if (response) {
  //           setALLPrepData((prevState: any) => {
  //             const updatedData = [...prevState];
  //             updatedData[0][station][0][line][index].isChecked = isChecked;
  //             return updatedData;
  //           });
  //           toast.success("Saved Successfully!", {
  //             position: toast.POSITION.TOP_RIGHT,
  //             autoClose: 2000, // Close the toast after 2000 milliseconds (2 seconds)
  //           });
  //         } else {
  //           toast.success("Failed to Save. Try again!", {
  //             position: toast.POSITION.TOP_RIGHT,
  //             autoClose: 2000, // Close the toast after 2000 milliseconds (2 seconds)
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error); // Handle error
  //       });
  //   };

  useEffect(() => {
    getPreparationData();
  }, []);

  const handleClickSave = () => {
    const selectedRowsData: any[] = [];
    allPrepData.forEach((data: { [x: string]: { [x: string]: any[] }[] }) => {
      Object.keys(data).forEach((station) => {
        Object.keys(data[station][0]).forEach((lineType) => {
          data[station][0][lineType].forEach((row: any) => {
            if (row.isChecked === undefined) {
              row.isChecked = false;
            }

            selectedRowsData.push({
              chassis_number: row.chassisNo,
              line_type: lineType,
              station_name: station,
              is_checked: row.isChecked,
            });
          });
        });
      });
      toast.success("Saved Successfully!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000, // Close the toast after 2000 milliseconds (2 seconds)
      });
    });

    console.log("selectedRowsData", selectedRowsData);
    // Send selectedRowsData to the API

    axios
      .post(`${REACT_APP_API_BASE_URL}/update-checkbox`, selectedRowsData)
      .then((response) => {
        console.log(response.data); // Handle success response
      })
      .catch((error) => {
        console.error("Error:", error); // Handle error
      });
  };

  const handleAlertOptionsChange = async (row: any, value: any) => {
    let requestBody = {
      chassisNo: row.chassisNo,
      stationAlert: value,
    };
    try {
      await httpClient.put(
        `api/update_station_alert_to_preparation_queue/${row.id}`,
        requestBody
      );
      await getPreparationData();
      toast.success("Saved Successfully!");
    } catch (e) {
      toast.error("Failed!");
    }
  }

  const PreperationTableHead = () => (
    <TableHead>
      <TableRow style={{ backgroundColor: "rgb(59, 17, 80)" }}>
        <TableCell style={{ width: "120px" }}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#fff",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            Station Name
          </Typography>
        </TableCell>
        <TableCell style={{ width: "80px" }}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#fff",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            Line View
          </Typography>
        </TableCell>
        <TableCell style={{ width: "120px" }}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#fff",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            Priority No
          </Typography>
        </TableCell>
        <TableCell style={{ width: "100px" }}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#fff",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            Chassis No
          </Typography>
        </TableCell>
        <TableCell style={{ width: "200px" }}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#fff",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            Model
          </Typography>
        </TableCell>
        <TableCell style={{ width: "120px" }}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#fff",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            Station Alert
          </Typography>
        </TableCell>
        <TableCell style={{ width: "210px" }}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#fff",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            Notes
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );

  const isAdmin =
    authInfo.roles &&
    (authInfo.roles.includes("user.admin") ||
      authInfo.roles?.includes("production-manager"));

  const prepType = isAdmin
    ? null
    : (Object.keys(prepTypes).find((key) =>
        authInfo.roles?.includes(key)
      ) as keyof typeof prepTypes);

  return (
    <div
      className="p-0 ms-0"
      style={{ textAlign: "center", maxWidth: "1150px" }}
    >
      {isAdmin || prepType ? (
        <>
          <TableContainer component={Paper} style={{ marginTop: "8px" }}>
            <Table size="small">
              <PreperationTableHead />
              <TableBody>
                {loader ? (
                  <Typography className="loading">Loading...</Typography>
                ) : (
                  <>
                    {allPrepData && allPrepData.length > 0 ? (
                      Object.keys(allPrepData[0]).map(
                        (station: string, i: number) => (
                          <React.Fragment key={i}>
                            {(isAdmin ||
                              (prepType && i === prepTypes[prepType])) &&
                              Object.keys(allPrepData[0][station][0]).map(
                                (line: any, idx: number) =>
                                  allPrepData[0][station][0][line].map(
                                    (row: any, index: number) => (
                                      <TableRow
                                        key={index}
                                        style={{
                                          borderBottom:
                                            (line === "main" &&
                                              index ===
                                                allPrepData[0][station][0][
                                                  "main"
                                                ].length -
                                                  1) ||
                                            (idx === 1 &&
                                              index ===
                                                allPrepData[0][station][0][
                                                  "crv"
                                                ].length -
                                                  1 &&
                                              isAdmin)
                                              ? "4px solid #d0d0d0"
                                              : "inherit",
                                        }}
                                      >
                                        {index === 0 && idx === 0 && (
                                          <TableCell
                                            style={{ width: "180px" }}
                                            rowSpan={
                                              allPrepData[0][station][0]["main"]
                                                ?.length +
                                              allPrepData[0][station][0]["crv"]
                                                ?.length
                                            }
                                          >
                                            <Typography
                                              variant="subtitle1"
                                              style={{
                                                fontWeight: 500,
                                                fontFamily:
                                                  "League Spartan, sans-serif",
                                              }}
                                            >
                                              {station}
                                            </Typography>
                                          </TableCell>
                                        )}
                                        {index === 0 && (
                                          <TableCell
                                            style={{
                                              width: "180px",
                                              textTransform: "uppercase",
                                            }}
                                            rowSpan={
                                              allPrepData[0][station][0][line]
                                                ?.length || 1
                                            }
                                          >
                                            <Typography
                                              variant="subtitle1"
                                              style={{
                                                width: "80px",
                                                color:
                                                  line === "crv"
                                                    ? "blue"
                                                    : "black",
                                                fontWeight: 500,
                                                fontFamily:
                                                  "League Spartan, sans-serif",
                                              }}
                                            >
                                              {line}
                                            </Typography>
                                          </TableCell>
                                        )}
                                        <TableCell
                                          style={{
                                            width: "120px",
                                            height: "30px",
                                            backgroundColor:
                                              isAdmin && row?.checked
                                                ? "lightgreen"
                                                : "inherit",
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle1"
                                            style={{
                                              color:
                                                line === "crv"
                                                  ? "blue"
                                                  : "black",
                                              fontWeight: 500,
                                              fontFamily:
                                                "League Spartan, sans-serif",
                                            }}
                                          >
                                            {prepType && (
                                              <Checkbox
                                                checked={row?.checked}
                                                onChange={async (event) => {
                                                  handleCheckboxChange(
                                                    event,
                                                    row
                                                  );
                                                }}
                                                // disabled={row?.isChecked}
                                              />
                                            )}
                                            {row?.priorityNo}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            width: "110px",
                                            height: "30px",
                                            backgroundColor:
                                              isAdmin && row?.checked
                                                ? "lightgreen"
                                                : "inherit",
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle1"
                                            style={{
                                              color:
                                                line === "crv"
                                                  ? "blue"
                                                  : "black",
                                              fontWeight: 500,
                                              fontFamily:
                                                "League Spartan, sans-serif",
                                            }}
                                          >
                                            {row?.chassisNo}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            width: "180px",
                                            height: "30px",
                                            backgroundColor:
                                              isAdmin && row?.checked
                                                ? "lightgreen"
                                                : "inherit",
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle1"
                                            style={{
                                              color:
                                                line === "crv"
                                                  ? "blue"
                                                  : "black",
                                              fontFamily:
                                                "League Spartan, sans-serif",
                                              backgroundColor:
                                                isAdmin && row?.checked
                                                  ? "lightgreen"
                                                  : "inherit",
                                              fontWeight: 500,
                                            }}
                                          >
                                            {row?.model}
                                          </Typography>
                                        </TableCell>
                                        {/* <TableCell
                                          style={{
                                            width: "180px",
                                            height: "30px",
                                            backgroundColor:
                                              isAdmin && row?.checked
                                                ? "lightgreen"
                                                : "inherit",
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle1"
                                            style={{
                                              color:
                                                line === "crv"
                                                  ? "blue"
                                                  : "black",
                                              fontWeight: 500,
                                              fontFamily:
                                                "League Spartan, sans-serif",
                                            }}
                                          >
                                            {row?.stationAlert}
                                          </Typography>
                                        </TableCell> */}
                                        <TableCell
                                          style={{
                                            width: "180px",
                                            height: "30px",
                                            backgroundColor:
                                              isAdmin && row?.checked
                                                ? "lightgreen"
                                                : "inherit",
                                          }}
                                        >
                                          <Typography
                                            variant="body1"
                                            style={{
                                              fontFamily:
                                                "League Spartan, sans-serif",
                                            }}
                                          >
                                            <Select
                                              name="stationAlert"
                                              value={row?.stationAlert ?? ""}
                                              onChange={(event) =>
                                                handleAlertOptionsChange(
                                                  row,
                                                  event.target.value
                                                )
                                              }
                                              className="station_alert_dropdown"
                                              displayEmpty
                                              inputProps={{
                                                "aria-label": "Without label",
                                              }}
                                              style={{
                                                width: "100%",
                                                fontFamily:
                                                  "League Spartan, sans-serif",
                                                backgroundColor:
                                                  row?.stationAlert === "Alert"
                                                    ? "#FF0000"
                                                    : row?.stationAlert ===
                                                      "Work Active"
                                                    ? "#FFA500"
                                                    : row?.stationAlert ===
                                                      "Work Complete"
                                                    ? "#008000"
                                                    : row?.stationAlert ===
                                                      "Stores"
                                                    ? "#0000FF"
                                                    : row?.stationAlert ===
                                                      "Maintenance"
                                                    ? "#FFFFFF"
                                                    : "transparent",
                                                color:
                                                  row?.stationAlert ===
                                                    "Alert" ||
                                                  row?.stationAlert ===
                                                    "Work Active" ||
                                                  row?.stationAlert ===
                                                    "Work Complete" ||
                                                  row?.stationAlert === "Stores"
                                                    ? "white"
                                                    : row?.stationAlert ===
                                                      "Maintenance"
                                                    ? "black"
                                                    : "black",
                                              }}
                                            >
                                              {stationAlertOptions.map(
                                                (item) => (
                                                  <MenuItem
                                                    value={item.value}
                                                    style={{
                                                      fontFamily:
                                                        "League Spartan, sans-serif",
                                                    }}
                                                  >
                                                    {item.label}
                                                  </MenuItem>
                                                )
                                              )}
                                            </Select>
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            width: "180px",
                                            height: "30px",
                                            backgroundColor:
                                              isAdmin && row?.checked
                                                ? "lightgreen"
                                                : "inherit",
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle1"
                                            style={{
                                              color:
                                                line === "crv"
                                                  ? "blue"
                                                  : "black",
                                              fontWeight: 500,
                                              fontFamily:
                                                "League Spartan, sans-serif",
                                            }}
                                          >
                                            {row?.notes}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )
                              )}
                          </React.Fragment>
                        )
                      )
                    ) : (
                      <Typography>No Data Found</Typography>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Typography
          variant="body1"
          style={{ marginTop: "8px", fontFamily: "League Spartan, sans-serif" }}
        >
          Not a valid role!!!
        </Typography>
      )}
    </div>
  );
}
