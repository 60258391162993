import { IUserInfo } from "../store/actions";
import orderStorage from "./orderStorage";
const USER_AUTH_KEY = "user_auth-info";
const userStorage = {
  getUserAuthInfo(): IUserInfo {
    return orderStorage.getJson(USER_AUTH_KEY) || {};
  },
  saveUserAuthInfo(authInfo: IUserInfo) {
    orderStorage.setJson(USER_AUTH_KEY, {
      ...authInfo,
    });
  },
  updateUserAuthToken(authInfo: IUserInfo) {
    orderStorage.setJson(USER_AUTH_KEY, {
      ...authInfo,
    });
  },
  // getUserInfo() {
  //   return orderStorage.getJson(USER_KEY) || {};
  // },
  // saveUserInfo(userInfo: IUserInfo) {
  //   orderStorage.setJson(USER_KEY, { ...userInfo });
  // },
  clearData() {
    orderStorage.clear(USER_AUTH_KEY);
  },
};

export default userStorage;
